var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "提示",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      !_vm.fromobj.print_pdf_right && !_vm.newdata
        ? _c(
            "div",
            {
              staticClass: "contents",
              staticStyle: { "text-align": "center" },
            },
            [
              _vm._v(
                " 不是当天的供货单，生成时间" +
                  _vm._s(_vm.supplierDate) +
                  "，不可打印； "
              ),
            ]
          )
        : _c(
            "div",
            { staticClass: "contents" },
            [
              _c("div", { staticClass: "tip-wrap" }, [
                _vm._v(" " + _vm._s(_vm.contentTitle) + " "),
              ]),
              _c(
                "el-form",
                {
                  ref: "refForm",
                  attrs: { model: _vm.formData, rules: _vm.formRules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "原因：", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "输入原因",
                          maxlength: "1000",
                          rows: 3,
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "remark", $$v)
                          },
                          expression: "formData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          !_vm.fromobj.print_pdf_right && !_vm.newdata
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.dialogloding },
                  on: { click: _vm.onHandleClose },
                },
                [_vm._v("确认")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.dialogloding },
                  on: { click: _vm.onHandleSubmit },
                },
                [_vm._v("确认")]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }