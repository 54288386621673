<template>
  <section class="modules-wrap">
    <!-- table start -->
    <div class="table">
      <div v-for="(item, index) of tableData" :key="item[id]" class="table-row">
        <!-- 头部 start -->
        <div
          class="table-row-header"
          @click="onHandleRowClick(item)"
          :class="{ 'no-border': isNoBorder(item[id]) }"
        >
          <div class="header-content">
            <!-- {{ `ID：${item.id} 名称：${item.name}` }} -->
            <div class="content-left">
              {{ `${index + 1}# ${item.product_title}` }}
            </div>
            <div>
              <span class="label">总件数：</span
              >{{ item.product_number }}&nbsp;&nbsp;/&nbsp;
              <span class="label">等级：</span
              >{{ item.product_grade }}&nbsp;&nbsp;/&nbsp;
              <span class="label">规格：</span>{{ item.product_spec }}
            </div>
          </div>
          <div class="header-icon">
            <i
              :class="
                expandIds.includes(item[id])
                  ? 'el-icon-arrow-up'
                  : 'el-icon-arrow-down'
              "
            ></i>
          </div>
        </div>
        <!-- 头部 end -->
        <!-- 内容区 start -->
        <div class="table-row-content" v-if="expandIds.includes(item[id])">
          <el-table :data="item.child" style="width: 100%">
            <el-table-column
              label="自提点名称"
              prop="delivery_name"
              minWidth="200"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="城市仓名称"
              prop="logistics_name"
              minWidth="200"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="车位号"
              minWidth="150"
              prop="carNumber"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.carNumber || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="应上车数量"
              prop="origin_number"
              minWidth="100"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- 内容区 end -->
      </div>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 保存展开的数据key
      expandIds: [],
      id: "product_id",
    };
  },
  computed: {
    /**
     * 当为最后一条数据，且收起状态，不展示border
     */
    isNoBorder() {
      return (data) => {
        let res =
          data === this.tableData[this.tableData.length - 1][this.id] &&
          !this.expandIds.includes(data);
        return res;
      };
    },
  },
  methods: {
    /**
     * 点击列表行，整行展开，再次点击收起
     */
    onHandleRowClick(row) {
      const exist = this.expandIds.includes(row[this.id]);
      if (!exist) {
        this.expandIds.push(row[this.id]);
      } else {
        this.expandIds = this.expandIds.filter((id) => id !== row[this.id]);
      }
      // console.log("🆒 onHandleRowClick", row, this.expandIds);
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid $border-color;
.modules-wrap {
  // padding: 6px;
  .table {
    border: $border;
    &-row {
      &:not(:first-child) {
        border-top: $border;
      }
      &-header {
        display: flex;
        height: 56px;
        padding: 0 20px;
        line-height: 56px;
        font-size: 20px;
        cursor: pointer;
        color: $theme-color;
        background-color: #fbfdf9;
        .header-content {
          flex: 1;
          display: flex;
          gap: 50px;
          .content-left {
            width: 400px;
          }
          .label {
            color: $border-color;
          }
        }
        &:not(:last-child) {
          border-bottom: $border;
        }
        &.no-border {
          border-bottom: none;
        }
      }
      &-content {
        // 调整table样式
        /deep/ .el-table {
          &__cell {
            font-size: 20px;
            color: $font-color;
            border-bottom: $border;
          }
          &__header {
            tr {
              .el-table__cell {
                &:not(:nth-last-child(2)) {
                  border-right: $border;
                }
              }
            }
          }
          &__body {
            .el-table__row {
              .el-table__cell {
                &:not(:last-child) {
                  border-right: $border;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
