<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
  <div class="contents" style="text-align: center;" v-if="!fromobj.print_pdf_right&&!newdata">
    不是当天的供货单，生成时间{{supplierDate}}，不可打印；
  </div>
    <div class="contents" v-else>
      <!-- 提示信息 start -->
      <div class="tip-wrap">
        {{ contentTitle }}
      </div>
      <!-- 提示信息 end -->

      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="原因：" prop="remark">
          <el-input
            type="textarea"
            v-model="formData.remark"
            placeholder="输入原因"
            maxlength="1000"
            :rows="3"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" :loading="dialogloding" @click="onHandleClose" v-if="!fromobj.print_pdf_right&&!newdata">确认</el-button>
      <el-button type="primary" :loading="dialogloding" @click="onHandleSubmit" v-else>确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { postPrintLogOfOrder } from "@/api/sheet-to-review/index";
import { formatDate, expirationValid } from "@/utils/tools/date.js";
import { validStrSpace } from "@/utils/tools/validate";

export default {
  name: "PrintMsgDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      formData: {
        supplier_order_no: "", // 供货单编号
        remark: "", // 打印原因
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      contentTitle: "", // 文字说明
      newdata:'',//是否当天
      fromobj:{},
      supplierDate:'',
      dialogloding: false, // 提交按钮loading
      formRules: {
        remark: [
          {
            required: true,
            trigger: ["blur", "change"],
            // message: "请输入原因！",
            validator(rule, value, callback) {
              if (!value) {
                callback(new Error("输入原因!"));
              } else if (validStrSpace(value)) {
                callback(new Error("支持1000个非空字符!"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.fromobj=data;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      data && this.setFormData(data);
    },
    /**
     * 回显信息
     */
    setFormData(data) {
      const { supplierOrder, supplierDate, print_ed_num } = data;
      this.formData.supplier_order_no = supplierOrder;
      const expiration = expirationValid(supplierDate, formatDate());
      let msg = `不是当天的供货单，生成时间 ${supplierDate}`;
      this.supplierDate=supplierDate
      // 未过期，非首次打印
      this.newdata = expiration;
      if (expiration && print_ed_num > 0) {
        msg = `这已是您第 ${print_ed_num} 次打印！`;
      }
      this.contentTitle = msg;
      // console.log("🆒 supplierDate", expiration, print_ed_num);
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$emit("onClose");
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.dialogloding = true;

        this.ajaxPostPrintLogOfOrder();
      });
    },
    /**
     * 提交原因
     */
    async ajaxPostPrintLogOfOrder() {
      try {
        const params = {
          ...this.formData,
        };
        await postPrintLogOfOrder(params);
        this.$emit("on-handle-print");
        this.onHandleClose();
        this.dialogloding = false;
      } catch (err) {
        this.dialogloding = false;
        console.log("ajax postPrintLogOfOrder err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .tip-wrap {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 550;
    text-align: center;
    color: $danger-color;
  }
  /deep/ .el-form {
    margin-left: 120px;
    &-item__error {
      left: 70px;
    }
  }
  .el-textarea {
    width: 80%;
  }
}
</style>
