var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "modules-wrap" }, [
    _c(
      "div",
      { staticClass: "table" },
      _vm._l(_vm.tableData, function (item, index) {
        return _c("div", { key: item[_vm.id], staticClass: "table-row" }, [
          _c(
            "div",
            {
              staticClass: "table-row-header",
              class: { "no-border": _vm.isNoBorder(item[_vm.id]) },
              on: {
                click: function ($event) {
                  return _vm.onHandleRowClick(item)
                },
              },
            },
            [
              _c("div", { staticClass: "header-content" }, [
                _c("div", { staticClass: "content-left" }, [
                  _vm._v(
                    " " + _vm._s(`${index + 1}# ${item.product_title}`) + " "
                  ),
                ]),
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("总件数：")]),
                  _vm._v(_vm._s(item.product_number) + "  /  "),
                  _c("span", { staticClass: "label" }, [_vm._v("等级：")]),
                  _vm._v(_vm._s(item.product_grade) + "  /  "),
                  _c("span", { staticClass: "label" }, [_vm._v("规格：")]),
                  _vm._v(_vm._s(item.product_spec) + " "),
                ]),
              ]),
              _c("div", { staticClass: "header-icon" }, [
                _c("i", {
                  class: _vm.expandIds.includes(item[_vm.id])
                    ? "el-icon-arrow-up"
                    : "el-icon-arrow-down",
                }),
              ]),
            ]
          ),
          _vm.expandIds.includes(item[_vm.id])
            ? _c(
                "div",
                { staticClass: "table-row-content" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: item.child },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "自提点名称",
                          prop: "delivery_name",
                          minWidth: "200",
                          "show-overflow-tooltip": "",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "城市仓名称",
                          prop: "logistics_name",
                          minWidth: "200",
                          "show-overflow-tooltip": "",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "车位号",
                          minWidth: "150",
                          prop: "carNumber",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.carNumber || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "应上车数量",
                          prop: "origin_number",
                          minWidth: "100",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }